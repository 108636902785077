import React, { useState } from 'react';
import "./AccountCreation.css"


const CreateAccountModal = ({ isOpen, closeModal }) => 
{
    const [formData, setFormData] = useState({
        username: '',
        identifiant: '',
        email: '',
        password: '',
        company: ''
    });

    const handleInputChange = (e) => 
    {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCreateAccount = () => 
    {
        // Handle create account logic
        console.log('Account created with data:', formData);
        closeModal();
    };

    if (!isOpen) 
    {
        return null;
    }

    return (
        <div className="modalOverlay">
            <div className="createAccountModal">
                <h2 className="createAccountHeader">Create Account</h2>

                <div className="accountField">
                    <label>Username</label>
                    <input
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                        className="accountInputField"
                        placeholder="Enter your username"
                    />
                </div>

                <div className="accountField">
                    <label>Identifiant</label>
                    <input
                        type="text"
                        name="identifiant"
                        value={formData.identifiant}
                        onChange={handleInputChange}
                        className="accountInputField"
                        placeholder="Enter your identifiant"
                    />
                </div>

                <div className="accountField">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="accountInputField"
                        placeholder="Enter your email"
                    />
                </div>

                <div className="accountField">
                    <label>Password</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        className="accountInputField"
                        placeholder="Enter your password"
                    />
                </div>

                <div className="accountField">
                    <label>Company</label>
                    <input
                        type="text"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        className="accountInputField"
                        placeholder="Enter your company name"
                    />
                </div>

                <button onClick={handleCreateAccount} className="createAccountButton">Create Account</button>
                <button onClick={closeModal} className="closeModalButton">Close</button>
            </div>
        </div>
    );
};

export default CreateAccountModal;
