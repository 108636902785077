import React, { useState, useEffect } from 'react';
import './Features.css';
import FeatureItem from './FeatureItem';
import { Link } from 'react-router-dom';

const Features = () => 
{
    // State to hold the current hovered item's data
    const featureItems = [
        {
            title: 'Real-time Messaging',
            descriptionSmall: 'Instant and secure messaging for teams and businesses.',
            descriptionBig: 'Stay connected with seamless messaging and collaboration tools.',
            imageUrl: 'https://images.unsplash.com/photo-1662974770404-468fd9660389?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },
        {
            title: 'Network analysis',
            descriptionSmall: 'Complete analysis from Instagram/TikTok/YouTube and more.',
            descriptionBig: 'Gain insights on audience engagement and growth trends across multiple platforms.',
            imageUrl: 'https://images.unsplash.com/photo-1526628953301-3e589a6a8b74?q=80&w=2006&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },
        {
            title: 'Manage company',
            descriptionSmall: 'Manage your company with all our tools.',
            descriptionBig: 'Track projects, manage teams, and boost productivity with advanced tools.',
            imageUrl: 'https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?q=80&w=2047&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        }
    ];
    const [indexToShow, setIndexToShow] = useState(0);
    const [fade, setFade] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(false);
            setTimeout(() => {
                // Update text after fade out
                setIndexToShow((prevIndex) => (prevIndex + 1) % featureItems.length);
                setFade(true); // Start fading in
            }, 500); // Wait for the fade-out transition duration (0.5s)
        }, 6000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, [featureItems.length]);


    return (
        <section className="features" id="features">
            <div className="container">
                <h2>Why EchoWave?</h2>
                <div className="feature-list">
                    {featureItems.map((item, index) => (
                        <FeatureItem 
                            key={index}
                            title={item.title} 
                            descriptionSmall={item.descriptionSmall} 
                            descriptionBig={item.descriptionBig}
                            imageUrl={item.imageUrl} // Clear when mouse leaves
                        />
                    ))}
                </div>

                {/* Display the descriptionBig and imageUrl in a floating box if hovering */}
            </div>


            <div className= {`hover-box ${fade ? 'fade-in' : 'fade-out'}`}>
    <div className="feature-container">
        <div className="feature-text">
            <h3>{featureItems[indexToShow].title}</h3>
            <p>{featureItems[indexToShow].descriptionBig}</p>
        </div>
        {featureItems[indexToShow].imageUrl && 
            <div className="feature-image">
                <img src={featureItems[indexToShow].imageUrl} alt={`${featureItems[indexToShow].title} icon`} />
            </div>
        }
    </div>


                </div>
            <div className='containerBeta dottedBackground'>
                <div className='card'>
    <div className='card-header'>
        <h3 className='card-title'>Echowave is Growing with You</h3>
    </div>
    <div className='descriptionText'>
        Wanna be a part of the process? <br />
        Be a part of our <strong style={{ color: 'red' }}>beta tester</strong> community!

    </div>
    <hr />
    <div className='beta-tester-program'>
        <h3 className='card-title'>What is the Beta Tester Program?</h3>
        <div className="descriptionText">
            Interested in <strong>helping</strong> us make this app a complete helper for companies? <br />
            With our beta tester program, you will get <strong>full access to the app</strong> by paying 
            <strong className="launching-price"> the launching price</strong>. <br />
            With this access, you will have the opportunity to:
        </div>

        <ul className='program-benefits'>
            <li><strong>Full app access:</strong> Nothing more to pay, have access to everything in the app.</li>
            <li><strong>Provide Feedback:</strong> Share your thoughts and suggestions.</li>
            <li><strong>Direct Communication:</strong> Talk with our team directly.</li>
            <li><strong>Influence Development:</strong> Help shape the features you want to see.</li>
            <li><strong>Future App Contributor:</strong>  You’ll have the opportunity to suggest app ideas when our newsletter is released.</li>
            
        </ul>
    </div>
    <Link to="/getaccessechowave">
        <button className="cta-button">Join us</button>
    </Link>
</div>

            </div>

        </section>

    );
}

export default Features;
