import React from "react";
import "./PrivacyPolicy.css";
import Footer from "../../EchowaveLandingPage/components/Footer";
import Header from "../../EchowaveLandingPage/components/Header";

const PrivacyPolicyPage = () =>
    {
        return (
            <section>
                <Header/>
                <div className="privacy-policy-container">
                    <h1>Privacy Policy</h1>
                    <p><strong>Effective Date: 27.09.2024</strong></p>
                    
                    <h2>1. Information We Collect</h2>
                    <p>We may collect and process the following types of information:</p>
                    <ul>
                        <li><strong>Personal Information:</strong> Name, email address, phone number, billing address, and payment details.</li>
                        <li><strong>Usage Data:</strong> Information about how you use our website or App.</li>
                        <li><strong>Cookies and Tracking Data:</strong> We use cookies and similar tracking technologies.</li>
                    </ul>
    
                    <h2>2. Social Media Data Collection</h2>
                    <p>When you use our social media analyzer, we want to assure you that:</p>
                    <ul>
                        <li>No data collected during the analysis is saved on our servers.</li>
                        <li>All data processing is done in real-time and is not stored.</li>
                        <li>Your privacy is our priority, and we do not retain any personal information from the analysis.</li>
                    </ul>
    
                    <h2>3. Direct Messaging System</h2>
                    <p>All messages sent through our direct messaging system are stored securely in our database. We want to assure you that:</p>
                    <ul>
                        <li>No messages are processed, sold, or used for any other purpose.</li>
                        <li>Our database is secure and hosted on a reliable service, ensuring the confidentiality of your messages.</li>
                    </ul>
    
                    <h2>4. How We Use Your Information</h2>
                    <p>We use the information collected for the following purposes:</p>
                    <ul>
                        <li>To provide and maintain our services.</li>
                        <li>To process payments.</li>
                        <li>To improve our services.</li>
                        <li>To communicate with you.</li>
                        <li>Legal obligations.</li>
                    </ul>
    
                    <h2>5. Sharing Your Information</h2>
                    <p>We do not sell, trade, or otherwise transfer your personal information to third parties, except in the following cases:</p>
                    <ul>
                        <li>Service Providers.</li>
                        <li>Legal Requirements.</li>
                    </ul>
    
                    <h2>6. Data Security</h2>
                    <p>We implement appropriate technical and organizational measures to protect your personal data.</p>
    
                    <h2>7. Your Data Protection Rights</h2>
                    <p>You have the following rights regarding your personal information:</p>
                    <ul>
                        <li>Access, Correction, Deletion, Restriction, and Data Portability.</li>
                    </ul>
    
                    <h2>8. Data Retention</h2>
                    <p>We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected.</p>
    
                    <h2>9. Cookies</h2>
                    <p>Our website uses cookies to enhance user experience.</p>
    
                    <h2>10. Changes to This Privacy Policy</h2>
                    <p>We reserve the right to modify this Privacy Policy at any time.</p>
    
                    <h2>11. Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                    <p><strong>Pulse Software</strong><br />
                    Email: dotsoft.pro@gmail.com<br /></p>
                </div>
                <Footer/>
            </section>
        );
    }
    

export default PrivacyPolicyPage;