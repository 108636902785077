import React, { useEffect } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Footer from './components/Footer';
import RoadMap from './components/Roadmap';
function App() {

    // Set page title dynamically using useEffect
    useEffect(() => {
        document.title = "Pulse software | introductionEchoWave"; // Change this title as needed
    }, []); // Empty dependency array means this runs once when the component mounts

    return (
        <div className="App">
        <Header />
            <Hero />
            <Features />
            <RoadMap/>
            <Footer />
        </div>

    );
}

export default App;
