import React from 'react';
import './GetStartedEchoWave.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const GetStartedEchoWave = () => 
    {
        return (
            <section className="get-started-page">
                <Header />
                <div className="get-started-container">
                    <div className="intro-section">
                        <h1>Welcome to EchoWave</h1>
                        <p>
                            EchoWave is the next-generation communication platform designed to enhance
                            your workflow with seamless collaboration, real-time messaging, and
                            integrated team management.
                        </p>
                        <h2>Why Join the Beta?</h2>
                        <p>
                            As a beta user, you'll be among the first to experience the power of EchoWave.
                            You'll help shape the product with your feedback while gaining early access
                            to premium features. 
                            <br/><strong style={{color: 'var(--darkerOpositiColor)'}}>In return, we're offering a special discounted rate 
                            during the beta period! (more than 50% off)</strong>
                        </p>
                    </div>
                    <hr/>
                    <div className="features-section">
                        <h2>What EchoWave Offers</h2>
                        <ul>
                            <li>Real-time team messaging</li>
                            <li>File sharing and collaboration tools</li>
                            <li>Personalized workspaces</li>
                            <li>Network data analyse</li>
                            <li>Secure and scalable infrastructure</li>
                        </ul>
                    </div>
    
                    <div className="subscription-section">
                        <h2>Subscription Plans</h2>
                        <p>
                            Subscribe to EchoWave and unlock all features (and future) with a simple monthly subscription.
                            No long-term commitments, cancel anytime. Choose the plan that works for you!
                        </p>
                        <div className="plan">
                            <h3>EchoWave Pro</h3>
                            <p><strong>51.70€/month</strong></p>
                            <p>Full access to all actual and future features.</p>
                        </div>
                    </div>
    
                    <div className="cta-section">
                        <h2>Ready to Get Started?</h2>
                        <p>
                            Don't miss out on the beta offer! Subscribe now and take your team's
                            productivity to the next level.
                        </p>
                        <form action="/create-checkout-session" method="POST">
                            <button type="submit" className="subscribe-button">
                                Subscribe
                            </button>
                        </form>
                    </div>
                </div>
                <Footer />
            </section>
        );
    };
    
    export default GetStartedEchoWave;
    