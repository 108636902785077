import React, { useEffect, useState } from 'react';
import UserConnecting from "./UserConnecting";
import UserInfAccount from "../UserInformationDisplayer/UserInformationAccount";
import { isCookieCredentialStillValid } from "../../DatabaseCommunication/GetDataFromUser";

const ConnectionModule = () => {
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(true); // Optional loading state

    useEffect(() => {
        const checkCredentials = async () => {
            const valid = await isCookieCredentialStillValid();
            setIsValid(valid);
            setLoading(false); // Update loading state once check is done
        };

        checkCredentials();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Optional loading indicator
    }

    return (
        <section className="containerConnection">
            {isValid ? <UserInfAccount /> : <UserConnecting />}
        </section>
    );
};

export default ConnectionModule;