import React, { useState } from "react";
import "../../App.css";
import "./Roadmap.css";

const RoadMap = () => {
    const roadmapData = {
        planning: [
            { title: "Feature A", description: "Plan to implement feature A", state: "Not started", debutDate: "2024-01-01", endDate: "2028-02-01" },
        ],
        doing: [
            { title: "Feature B", description: "Currently developing feature B", state: "In progress", debutDate: "2024-01-15", endDate: "2024-12-01" }
        ],
        done: [
            { title: "Feature C", description: "Feature C has been completed", state: "Completed", debutDate: "2023-11-01", endDate: "2024-01-01" }
        ]
    };

    const [visibleColumns, setVisibleColumns] = useState({
        planning: true,
        doing: true,
        done: true
    });

    const toggleColumn = (column) => {
        setVisibleColumns((prevState) => ({
            ...prevState,
            [column]: !prevState[column]
        }));
    };

    const calculateColor = (progress) => {
        const red = Math.round(255 * (1 - progress / 100)); // Decrease red as progress increases
        const green = Math.round(255 * (progress / 100)); // Increase green as progress increases
        return `rgb(${red}, ${green}, 0)`; // Return the RGB color
    };
    

    const renderCards = (items) => {
        return items.map((item, index) => {
            const progress = calculateProgress(item.debutDate, item.endDate);
            return (
                <div key={index} className={`roadmap-card ${item.state.toLowerCase().replace(" ", "-")}`}>
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                    <p><strong>State:</strong> {item.state}</p>
                    <p><strong>Debut Date:</strong> {item.debutDate}</p>
                    <p><strong>End Date:</strong> {item.endDate}</p>
                    <div className="progress-bar">
                        <div className="progress" style={{ width: `${progress}%`, backgroundColor: calculateColor(progress) }}></div>
                    </div>
                </div>
            );
        });
    };

    const calculateProgress = (debutDate, endDate) => {
        const start = new Date(debutDate).getTime();
        const end = new Date(endDate).getTime();
        const today = new Date().getTime();
        const progress = ((today - start) / (end - start)) * 100;
        return Math.min(Math.max(progress, 0), 100); // Clamp between 0 and 100
    };

    return (
        <section className="containerMainRdMp">
        <div className="containerRoadMap">
            <h3>Roadmap Echowave End 2024</h3>
            <div className="roadmap-columns">
                {Object.keys(roadmapData).map((column) => (
                    <div className="column" key={column}>
                        <h4 className="column-title" onClick={() => toggleColumn(column)}>
                            {column.charAt(0).toUpperCase() + column.slice(1)} {visibleColumns[column] ? '-' : '+'}
                        </h4>
                        {visibleColumns[column] && renderCards(roadmapData[column])}
                    </div>
                ))}
            </div>
        </div>
        </section>
    );
};

export default RoadMap;
