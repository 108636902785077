import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer" id="contact">
            <div className="container">
                <p>&copy; 2024 Pulse Software. All rights reserved.</p>
                <p>Contact us: <a href="mailto:dotsoft.pro@gmail.com">dotsoft.pro@gmail.com</a></p>
                <Link to="/privacypolicy"><button>Privacy Policy</button></Link>
                <Link to="/privacypolicy"><button>Terms of Service</button></Link>
            </div>
        </footer>
    );
}

export default Footer;
