import Cookies from "js-cookie";
import axios from 'axios';


const DoesUserHasEchoWave = (username) =>
{
    console.log(username + " has access");
    return false;
}

const IsUserValid = (username) =>
{
    return true;
}
const IsUserCredentialValid = async (username, password) => {
    if (username && password) {
        try {
            const response = await axios.post('https://f167-2a01-cb1d-82c0-5500-4972-19d7-809b-bf5a.ngrok-free.app/IsUserCredValid', {
                username,
                password,
            });
            console.log(response.data);

            // Check if the API response indicates the credentials are valid
            return response.data.isValid || false; // If isValid is true, return it; otherwise, return false
        } catch (error) {
            console.error("Error during API call:", error);
            return false; // Return false in case of an error
        }
    }
    console.log("return false");
    return false; // Return false if username or password is not provided
};

const isCookieCredentialStillValid = async () => {
    const usernameToken = Cookies.get('authPulseUsername');
    const passwordToken = Cookies.get('authPulsePassword');
    console.log(usernameToken + " and " + passwordToken);

    // Await the result of the async function
    const isValid = await IsUserCredentialValid(usernameToken, passwordToken);

    if (isValid) {
        console.log("valid");
        return true;        
    }
    console.log("error");
    return false;
};


export { DoesUserHasEchoWave, IsUserValid, isCookieCredentialStillValid, IsUserCredentialValid };