import React from "react";
import Cookies from "js-cookie";
import { DoesUserHasEchoWave } from "../../DatabaseCommunication/GetDataFromUser";
import { Link } from "react-router-dom";
import "./UserInformationAccount.css";

// You can import icons from a library like FontAwesome or Material Icons
import { FaCheckCircle, FaCog } from 'react-icons/fa';

const UserInfAccount = () =>
{
    const usernameToken = Cookies.get('authPulseUsername');
    return (
        <section className="containerAccInf">
            <div className="welcomeCard card">
                <img className="profileImage" src="/path/to/your/profile/image.jpg" alt="Profile" />
                <h2>Welcome, <strong>{usernameToken}</strong></h2>
                <p>Manage your account settings and explore the features available to you.</p>
            </div>

            <div className="cardsContainer">
                <div className="echoWaveCard card">
                    <FaCheckCircle className="icon" />
                    <h3>Echowave Beta Access</h3>
                    <p>{DoesUserHasEchoWave(usernameToken) 
                        ? 'Thank you for being one of our beta testers! You have full access to the app.'
                        : "Not a beta tester yet? Get ahead of the competition by joining now!"}
                    </p>
                    {!DoesUserHasEchoWave(usernameToken) && <Link to="/getaccessechowave"><button className="cta-button">Get Started</button></Link>}
                </div>

                <div className="accountInfoCard card">
                    <FaCog className="icon" />
                    <h3>Account Information</h3>
                    <p>Update your profile, password, or other account settings.</p>
                </div>
            </div>
        </section>
    );
}

export default UserInfAccount;
